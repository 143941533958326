import { PaymentGateway } from "@gqlTypes/PaymentGateway";
import { CreditCard } from "@graphql/orderAppGqlTypes/CreditCard";
import { PaymentMethod } from "@stripe/stripe-js";

import { defaultLocale, orderAppPaymentGatewayRegExp } from "@config";
import { currencySymbols } from "@providers/TranslationProvider";
import { Currency } from "@providers/TranslationProvider/types";

type PriceRange = {
  currency?: string;
  enforceLocale?: boolean;
  language?: string;
  max: number;
  min: number;
};

type FormatNumber = {
  amount: number;
  currency: string;
  currencyDisplay?: string;
  fractionDigits?: 0 | 2;
  language?: string | string[];
};

export const amountToCents = (amount: number) => Math.round(amount * 100);

export const serializeStripeCard = ({
  brand,
  exp_month,
  exp_year,
  last4,
}: PaymentMethod.Card): Omit<CreditCard, "__typename"> => ({
  brand,
  expMonth: String(exp_month),
  expYear: String(exp_year),
  lastDigits: last4,
});

export const getOrderAppPaymentGetewayId = (
  gatewayList: PaymentGateway[]
): string =>
  gatewayList.find(({ id }) => id.match(orderAppPaymentGatewayRegExp))?.id ??
  "";

export const formatNumber = ({
  amount,
  currency,
  language,
  fractionDigits,
  currencyDisplay = "symbol",
}: FormatNumber) => {
  const options: Intl.NumberFormatOptions = {
    currency,
    currencyDisplay: currencyDisplay as any,
    style: "currency",
  };

  // Check if the number is a whole number
  if (fractionDigits) {
    options.minimumFractionDigits = fractionDigits;
    options.maximumFractionDigits = fractionDigits;
  } else if (Number.isInteger(amount)) {
    options.minimumFractionDigits = 0;
    options.maximumFractionDigits = 0;
  } else {
    options.minimumFractionDigits = 2;
    options.maximumFractionDigits = 2;
  }

  return new Intl.NumberFormat(language, options).format(amount);
};

export const combinePrice = (
  prices: number[],
  currency: string,
  language: string,
  enforceLocale = true
) =>
  prices.map(amount =>
    formatNumber({
      amount,
      currency,
      language: enforceLocale ? language : "de",
    })
  );

export const getPriceRange = ({
  min,
  max,
  language = "en",
  currency = "GBP",
  enforceLocale = true,
}: PriceRange) => {
  if (min === max || max === 0) {
    return formatNumber({
      amount: min,
      currency,
      language: enforceLocale ? language : "de",
    });
  }
  return combinePrice([min, max], currency, language, enforceLocale).join(
    " - "
  );
};

export function getCurrencySymbol(
  locale: string | undefined,
  currency: Currency
) {
  if (locale === defaultLocale) {
    return;
  }
  return (0)
    .toLocaleString(locale, {
      currency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: "currency",
    })
    .replace(/\d/g, "")
    .trim();
}

export function getCurrencySymbolOnly(
  locale: string | undefined,
  currency: Currency
) {
  if (locale === defaultLocale) {
    return;
  }

  return currencySymbols[currency] || currency;
}
