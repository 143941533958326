import {
  ProductAvailabilityQuery,
  ProductAvailabilityQueryVariables,
} from "@gqlTypes/ProductAvailabilityQuery";
import { useTypedQuery } from "@graphql/hooks";
import gql from "graphql-tag";

import { MetadataKey } from "@config";

import {
  addressValidationFragment,
  collectionProductFragment,
  countryFragment,
  metadataFragment,
  orderFragment,
  pageFragment,
  pageInfoFragment,
  pricedProductVariantFragment,
  productCollectionFragment,
  productCollectionSEOFragment,
  productMediaFragment,
  productThumbnailFragment,
  productTypeFragment,
  selectedAttributeFragment,
  userFragment,
} from "./fragments";

export const addressValidationFieldsQuery = gql`
  ${addressValidationFragment}
  query AddressValidationFields(
    $countryCode: CountryCode!
    $countryArea: String
  ) {
    addressValidationRules(
      countryCode: $countryCode
      countryArea: $countryArea
    ) {
      ...AddressValidation
    }
  }
`;

export const sliderCollectionQuery = gql`
  ${collectionProductFragment}
  ${metadataFragment}
  query SliderCollection(
    $id: ID!
    $slug: String
    $channel: String!
    $pageSize: Int!
    $languageCode: LanguageCodeEnum = EN
  ) {
    collection(id: $id, slug: $slug, channel: $channel) {
      name
      slug
      metadata {
        ...MetadataItem
      }
      products(first: $pageSize) {
        edges {
          node {
            ...CollectionProduct
          }
        }
      }
    }
  }
`;

export const enabledCountriesQuery = gql`
  ${countryFragment}
  query EnabledCountries {
    shop {
      countries {
        ...Country
      }
    }
  }
`;

export const pageQuery = gql`
  ${pageFragment}
  query Page($slug: String!) {
    page(slug: $slug) {
      ...Page
    }
  }
`;

export const userQuery = gql`
  ${userFragment}
  query UserQuery($channel: String) {
    me {
      ...User
    }
  }
`;

export const checkCustomerExists = gql`
  query CheckCustomer($email: String) {
    customers(filter: { search: $email }, first: 1) {
      totalCount
      edges {
        node {
          email
        }
      }
    }
  }
`;

export const getIndividualOrderQuery = gql`
  ${orderFragment}
  query GetIndividualOrder($id: ID!, $languageCode: LanguageCodeEnum = EN) {
    order(id: $id) {
      ...Order
    }
  }
`;

/**
 * TODO: Refactor this query to use ID instead of token as this will be deprecated in the future.
 */
export const orderDetailsQuery = gql`
  ${orderFragment}
  query OrderDetails($token: UUID!, $languageCode: LanguageCodeEnum = EN) {
    orderByToken(token: $token) {
      ...Order
    }
  }
`;

export const orderExistenceQuery = gql`
  query OrderExistence($token: UUID!) {
    orderByToken(token: $token) {
      token
      id
    }
  }
`;

export const collectionDataQuery = gql`
  ${productCollectionFragment}
  ${productCollectionSEOFragment}
  query CollectionData(
    $slug: String
    $id: ID
    $channel: String!
    $languageCode: LanguageCodeEnum = EN
  ) {
    collection(slug: $slug, channel: $channel, id: $id) {
      ...Collection
      ...CollectionSeoFields
    }
  }
`;

export const productVariantsQuery = gql`
  ${pricedProductVariantFragment}
  query ProductVariants(
    $ids: [ID!]!
    $channel: String!
    $limit: Int
    $languageCode: LanguageCodeEnum = EN
  ) {
    productVariants(ids: $ids, channel: $channel, first: $limit) {
      edges {
        node {
          ...PricedProductVariant
        }
      }
    }
  }
`;

export const userMetaQuery = gql`
  ${metadataFragment}
  query UserMetaQuery($email: String, $id: ID) {
    user(email: $email, id: $id) {
      email
      id
      metadata {
        ...MetadataItem
      }
    }
  }
`;

export const productAvailabilityQuery = gql`
  query ProductAvailabilityQuery(
    $id: ID
    $slug: String
    $channel: String!
    $countryCode: CountryCode
  ) {
    product(id: $id, slug: $slug, channel: $channel) {
      variants {
        id
        quantityAvailable(countryCode: $countryCode)
        quantityLimitPerCustomer
        metafields(keys: [
          "${MetadataKey.VARIANT_RATING}",
          "${MetadataKey.VARIANT_UNPUBLISHED}",
          "${MetadataKey.VARIANT_ARCHIVING}",
        ])
      }
    }
  }
`;

export const userLikesQuery = gql`
  query UserLikesQuery($email: String, $id: ID, $keys: [String!]) {
    user(email: $email, id: $id) {
      metafields(keys: $keys)
    }
  }
`;

export const productUserLikesQuery = gql`
  query ProductUserLikesQuery(
    $id: ID
    $channel: String!
  ) {
    product(id: $id, channel: $channel) {
      metafields(keys: [
        "${MetadataKey.SOURCE_LIKES}",
      ])
    }
  }
`;

export const collectionUserLikesQuery = gql`
  query CollectionUserLikesQuery(
    $id: ID
    $channel: String!
  ) {
    collection(id: $id, channel: $channel) {
      metafields(keys: [
        "${MetadataKey.SOURCE_LIKES}",
      ])
    }
  }
`;

export const collectionsQuery = gql`
  ${pageInfoFragment},
  query CollectionsQuery($channel: String!, $after: String, $type: String!) {
    collections(
      channel: $channel
      first: 100
      after: $after
      sortBy: {
        field: NAME,
        direction: ASC
      }
      filter: {
        metadata: {
          key: "${MetadataKey.COLLECTION_SOURCE}",
          value: $type
        },
        published: PUBLISHED
      }
    ) {
      edges {
        node {
          name
          slug
          products {
            totalCount
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
`;

export const collectionsQueryPrevious = gql`
  ${pageInfoFragment},
  query CollectionsQueryPrevious($channel: String!, $before: String, $type: String!) {
    collections(
      channel: $channel
      last: 100
      before: $before
      sortBy: {
        field: NAME,
        direction: ASC
      }
      filter: {
        metadata: {
          key: "${MetadataKey.COLLECTION_SOURCE}",
          value: $type
        },
        published: PUBLISHED
      }
    ) {
      edges {
        node {
          name
          slug
          products {
            totalCount
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
`;

export const useProductAvailabilityQuery = () =>
  useTypedQuery<ProductAvailabilityQuery, ProductAvailabilityQueryVariables>(
    productAvailabilityQuery,
    { skip: true }
  );

export const userLikedProductsQuery = gql`
  ${productThumbnailFragment}
  ${selectedAttributeFragment}
  ${productMediaFragment}
  ${productTypeFragment}
  query UserLikedProductsQuery(
    $channel: String!
    $ids: [ID!]
    $first: Int!
    $languageCode: LanguageCodeEnum = EN
  ) {
    productVariants(channel: $channel, first: $first, ids: $ids) {
      edges {
        node {
          name
          id
          attributes {
            ...SelectedAttribute
          }
          media {
            ...ProductMedia
          }
          product {
            id
            name
            slug
            productType {
              ...ProductType
            }
            attributes {
              ...SelectedAttribute
            }
            thumbnail(size: 1024, format: WEBP) {
              ...Thumbnail
            }
          }
        }
      }
    }
  }
`;

export const userLikedCollectionsQuery = gql`
  query UserLikedCollectionsQuery(
    $channel: String!
    $ids: [ID!]
    $first: Int!
  ) {
    collections(filter: { ids: $ids }, first: $first, channel: $channel) {
      edges {
        node {
          name
          id
          slug
          backgroundImage(size: 1024, format: WEBP) {
            url
            alt
          }
        }
      }
    }
  }
`;
